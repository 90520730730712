import axios from "axios";
import Swal from "sweetalert2";
import router from "../router";

export default {

    namespaced: true,

    state: {
        userData: {},
        permissions: {},
        roles: {},
        isAuthenticated: localStorage.getItem('api_token') || '',
        token: localStorage.getItem('api_token') || '',
        usrPerms: [],
    },

    getters: {
        user: state => state.userData,
        permissions: state => state.permissions,
        roles: state => state.roles,
        error: state => state.errors,
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
        usrPerms: (state) => state.usrPerms,
    },

    actions: {

        getUserData({commit}) {
            const user_id = localStorage.getItem('user_id')
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/user/profile/details/${user_id}?api_token=${localStorage.getItem('api_token')}`)
                    .then(response => {
                    commit("setUserData", response.data.data);
                });
        },

        loginRequest({commit}, data) {
            commit("setErrors", {}, {root: true});
            axios
                .post(`${process.env.VUE_APP_URL}/api/v1/login?platform=backoffice`, data )
                .then(response => {
                    if (response.data.status_code === 400) {
                        return Swal.fire({
                            title: "",
                            text: response.data.message,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: false
                        });
                    }
                    if (response.data.user.api_token) {
                        commit("setUserData", response.data.user);
                        commit("setAuth", response.data.user)

                        localStorage.setItem('api_token', response.data.user.api_token);
                        localStorage.setItem('user_id', response.data.user.id);
                        // this.getRoleWisePermission;
                        router.push('/dashboard');
                    }
                });
        },

        getPermissions({commit}) {
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/permission/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    commit("permissions", response.data.data);
                });
        },

        getUserPermissions({commit}) {
            var url = `${process.env.VUE_APP_URL}/api/v1/user/permission/list?api_token=${localStorage.getItem('api_token')}`;
            return axios.get(url)
                .then(res => {
                    commit('SET_USER_PERMISSIONS', res.data.data);
                    return res;
                }).catch((err) => {
                    return err
                })

        },

        getRoles({ commit }) {
            axios
                .get(`${process.env.VUE_APP_URL}/api/v1/role/list?api_token=${localStorage.getItem('api_token')}`)
                .then(response => {
                    commit("roles", response.data.data);
                });
        },

        // getRoleWisePermission({commit}){
        //     axios
        //         .get(`${process.env.VUE_APP_URL}/api/v1/role/show/${this.state.userData.roles.id}`)
        //         .then(response => {
        //             commit("permissions", response.data.data);
        //         });
        // }
    },

    mutations: {
        setUserData(state, user) {
            state.userData = user;
            state.isAuthenticated = true;
        },

        permissions(state, permission) {
            state.permissions = permission;
        },

        roles(state, roles) {
            state.roles = roles;
        },

        setAuth(state, user) {
            state.isAuthenticated = true;
            state.user = user;
        },

        clearToken(state) {
            localStorage.removeItem('api_token');
            state.api_token = '';
        },
        SET_USER_PERMISSIONS(state, permissions) {
            state.usrPerms = permissions
        }

    },

};
